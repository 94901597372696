<template>
  <Toast class="w-22rem" position="bottom-left" />
  <div
    class="filter flex justify-content-center align-items-center flex-column"
  >
    <div :class="`filter__block ${$store.state.addClassWidth}`">
      <label for="card">Карты</label>
      <MultiSelect
        id="card"
        v-model="selectedCardModel"
        :filter="true"
        :options="$store.state.cards"
        class="w-full sm:w-1rem md:w-full lg:w-full mt-2"
        optionLabel="name"
        optionValue="id"
        placeholder="Выберите карту"
        @change="getChanges"
      >
        <template #option="slotProps">
          <div
            class="left-100 card-item flex align-items-center justify-content-between"
          >
            <img
              :src="`${slotProps.option.icon_url}`"
              alt="914"
              class="border-round-xs"
              width="30"
            />
            <div class="ml-2">
              {{ slotProps.option.name }}, {{ slotProps.option.id }}
            </div>
          </div>
        </template>
      </MultiSelect>
    </div>
    <div :class="`filter__block ${$store.state.addClassWidth}`">
      <label for="fuel">Топливо</label>
      <MultiSelect
        id="fuel"
        v-model="selectedService"
        :filter="true"
        :options="$store.state.services"
        class="w-full sm:w-1rem md:w-full lg:w-full mt-2"
        optionLabel="name"
        optionValue="code"
        placeholder="Выберите топливо"
        @change="getChanges"
      />
    </div>
    <div :class="`filter__block ${$store.state.addClassWidth}`">
      <label for="brand">Бренд</label>
      <MultiSelect
        id="brand"
        v-model="selectedBrand"
        :filter="true"
        :options="$store.state.brands"
        class="w-full sm:w-1rem md:w-full lg:w-full mt-2"
        optionLabel="name"
        optionValue="code"
        placeholder="Выберите бренд"
        @change="getChanges"
      />
    </div>
    <div :class="`filter__block ${$store.state.addClassWidth}`">
      <label for="services">Услуги</label>
      <MultiSelect
        id="services"
        v-model="selectedAdditional"
        :filter="true"
        :options="$store.state.additionals"
        class="w-full sm:w-1rem md:w-full lg:w-full mt-2"
        optionLabel="name"
        optionValue="code"
        placeholder="Выберите услугу"
        @change="getChanges"
      />
    </div>
    <div
      :class="`filter__block flex flex-column align-items-start justify-content-around transition-all transition-duration-500 ${$store.state.addClassWidth}`"
    >
      <div class="flex flex-row align-items-center">
        <Checkbox
          id="selectedOnlyAZSStations"
          v-model="selectedOnlyAZSStations"
          class="mr-2"
          value="true"
          @change="getChanges"
        />
        <label for="selectedOnlyAZSStations"
          >Показывать только АЗС станции</label
        >
      </div>
    </div>
    <Transition name="checkbox-filter-fade">
      <div
        v-if="Object.values($store.state.selectedCardModel).includes('7005')"
        :class="`filter__block flex flex-column align-items-start justify-content-around transition-all transition-duration-500 ${$store.state.addClassWidth}`"
      >
        <div class="flex flex-row align-items-center mb-3">
          <Checkbox
            id="selectedPhonePayment"
            v-model="selectedPhonePayment"
            class="mr-2"
            value="phone_pay"
            @change="getChanges"
          />
          <label for="selectedPhonePayment">Оплата из машины</label>
        </div>
        <div class="flex flex-row align-items-center mb-3">
          <Checkbox
            id="selectedBarcodePayment"
            v-model="selectedBarcodePayment"
            class="mr-2"
            value="barcode_pay"
            @change="getChanges"
          />
          <label for="selectedBarcodePayment">Оплата штрихкодом</label>
        </div>
        <div class="flex flex-row align-items-center mb-3">
          <Checkbox
            id="barcode-pay"
            v-model="selectedAZSGazpomneft"
            class="mr-2"
            value="OwnGPN"
            @change="getChanges"
          />
          <label for="barcode-pay">АЗС Газпромнефть</label>
        </div>
        <div class="flex flex-row align-items-center mb-3">
          <Checkbox
            id="barcode-pay"
            v-model="selectedAZSFranchisees"
            class="mr-2"
            value="FRAN"
            @change="getChanges"
          />
          <label for="barcode-pay">АЗС Франчайзи ОПТИ</label>
        </div>
        <div class="flex flex-row align-items-center mb-3">
          <Checkbox
            id="barcode-pay"
            v-model="selectedAZSPartners"
            class="mr-2"
            value="EXT,RENT"
            @change="getChanges"
          />
          <label for="barcode-pay">АЗС Партнеров ОПТИ</label>
        </div>
        <div class="flex flex-row align-items-center mb-3">
          <Checkbox
            id="barcode-pay"
            v-model="selectedOPTI"
            class="mr-2"
            value="OPTI"
            @change="getChanges"
          />
          <label for="barcode-pay">ОПТИ</label>
        </div>
        <div class="flex flex-row align-items-center">
          <Checkbox
            id="barcode-pay"
            v-model="selectedOPTI24"
            class="mr-2"
            value="OwnGPN-CS"
            @change="getChanges"
          />
          <label for="barcode-pay">ОПТИ 24</label>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import { requestStationsIfRouteWasBuild } from "@/services/utils";
import { requestsAPIMixin } from "@/mixins/requestsAPIMixin";
import { setPositionsMixins } from "@/mixins/setPositionsMixin";
import { getAddressesMixin } from "@/mixins/getAddressesMixin";
import { nearestStationsToRouteMixin } from "@/mixins/nearestStationsToRouteMixin";
import { getAdditionals, getServices } from "@/services/requestsToAPI";

export default {
  name: "Filters",
  mixins: [
    requestsAPIMixin,
    setPositionsMixins,
    getAddressesMixin,
    nearestStationsToRouteMixin,
  ],
  computed: {
    selectedCardModel: {
      get() {
        return this.$store.state.selectedCardModel;
      },
      set(value) {
        this.$store.commit("setSelectedCardModel", value);
      },
    },
    selectedBrand: {
      get() {
        return this.$store.state.selectedBrand;
      },
      set(value) {
        this.$store.commit("setSelectedBrand", value);
      },
    },
    selectedAdditional: {
      get() {
        return this.$store.state.selectedAdditional;
      },
      set(value) {
        this.$store.commit("setSelectedAdditional", value);
      },
    },
    selectedService: {
      get() {
        return this.$store.state.selectedService;
      },
      set(value) {
        this.$store.commit("setSelectedService", value);
      },
    },
    selectedPhonePayment: {
      get() {
        return this.$store.state.selectedPhonePayment;
      },
      set(value) {
        this.$store.commit("setSelectedPhonePayment", value);
      },
    },
    selectedBarcodePayment: {
      get() {
        return this.$store.state.selectedBarcodePayment;
      },
      set(value) {
        this.$store.commit("setSelectedBarcodePayment", value);
      },
    },
    selectedAZSGazpomneft: {
      get() {
        return this.$store.state.selectedAZSGazpomneft;
      },
      set(value) {
        this.$store.commit("setSelectedAZSGazpomneft", value);
      },
    },
    selectedAZSPartners: {
      get() {
        return this.$store.state.selectedAZSPartners;
      },
      set(value) {
        this.$store.commit("setSelectedAZSPartners", value);
      },
    },
    selectedAZSFranchisees: {
      get() {
        return this.$store.state.selectedAZSFranchisees;
      },
      set(value) {
        this.$store.commit("setSelectedAZSFranchisees", value);
      },
    },
    selectedOPTI24: {
      get() {
        return this.$store.state.selectedOPTI24;
      },
      set(value) {
        this.$store.commit("setSelectedOPTI24", value);
      },
    },
    selectedOPTI: {
      get() {
        return this.$store.state.selectedOPTI;
      },
      set(value) {
        this.$store.commit("setSelectedOPTI", value);
      },
    },
    selectedOnlyAZSStations: {
      get() {
        return this.$store.state.selectedOnlyAZSStations;
      },
      set(value) {
        this.$store.commit("setSelectedOnlyAZSStations", value);
      },
    },
  },
  methods: {
    async getChanges() {
      const stationTypeFilter = {
        selectedPhonePayment: this.selectedPhonePayment[0],
        selectedBarcodePayment: this.selectedBarcodePayment[0],
        selectedAZSGazpomneft: this.selectedAZSGazpomneft[0],
        selectedAZSPartners: this.selectedAZSPartners[0],
        selectedAZSFranchisees: this.selectedAZSFranchisees[0],
        selectedOPTI24: this.selectedOPTI24[0],
        selectedOPTI: this.selectedOPTI[0],
      };
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      params.set("emitents", this.$store.state.selectedCardModel);
      this.$store.commit(
        "setAdditionals",
        await getAdditionals(params, this.$store.state.selectedCardModel)
      );
      this.$store.commit(
        "setServices",
        await getServices(params, this.$store.state.selectedCardModel)
      );
      let stationTypes = [];
      for (let stationType in stationTypeFilter) {
        if (stationTypeFilter[stationType]) {
          stationTypes.push(stationTypeFilter[stationType]);
        }
      }
      this.$store.commit("setStationTypeFilter", stationTypes.join(","));
      if (this.$store.state.selectedCardModel.length === 0) {
        params.delete("emitents");
        this.$store.commit("setCardNumberFromPopup", null);
      }
      url.search = params.toString();
      window.history.replaceState(null, null, url.toString());
      this.$store.commit(
        "setSelectedCard",
        this.$store.state.selectedCardModel
      );
      if (this.$store.state.routeWasBuild) {
        const result = await this.requestStationsMixinMethod("", true);
        await requestStationsIfRouteWasBuild(result);
      } else {
        await this.requestStationsMixinMethod();
      }
      if (this.$store.state.nearestStationsToRoute.length > 0) {
        await this.requestNearestListStationsMixinMethod(
          this.$store.state.nearestStationsToRouteSearchField
        );
      }
      this.$toast.add({
        severity: "info",
        summary: "Инфо",
        detail: "Фильтр применен!",
        closable: true,
        life: 1500,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.logo-sm {
  background-image: var(--logo-sm-url);
  width: var(--logo-width) !important;
}

.header-main__container {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.header-main__second-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

a {
  text-decoration: none;
}

.aside-sidenav {
  background-color: rgba(0, 0, 0, 0);
}

.filter {
  height: 100%;

  &:nth-child(1) {
    margin: 0;
  }
}

.filter__block {
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 15px 0;

  &:nth-child(1) {
    margin: 0;
  }
}

.filter__autocomplete-flex {
  display: flex;
  margin-top: 10px;
}

.filter__div {
  margin: 0 0 15px 0;
}

.filter__div-input {
  width: 100%;
}

.filter__block-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;

  span {
    padding-left: 10px;
  }
}

.nearest-statiotion-info {
  display: flex;
  flex-direction: column;
  height: 20vh;
  justify-content: space-between;
}

.p-button .p-button-raised {
  box-shadow: 0 5px 6px -2px rgb(0 0 0 / 32%), 0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
}

.checkbox-filter-fade-enter-active {
  transition: all 0.3s ease-out;
}

.checkbox-filter-fade-leave-active {
  transition: all 1s ease-out;
}

.checkbox-filter-fade-enter-from,
.checkbox-filter-fade-leave-to {
  transform: translateX(100px);
  opacity: 0;
}

/* media */

@media screen and (max-width: 475px) {
  .p-multiselect {
    width: 80vw;
  }
}

@media screen and (max-width: 400px) {
  .header-main__container {
    flex-direction: column;
  }
}
</style>
