// Third-party imports
import { createApp } from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBarcode,
  faCar,
  faCircleInfo,
  faCompass,
  faFilter,
  faGasPump,
  faLock,
  faMagnifyingGlassLocation,
  faMap,
  faMobile,
  faRoute,
  faUnlock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import PrimeVue from "primevue/config";
import YmapPlugin from "vue-yandex-maps";
import ToastService from "primevue/toastservice";

// PrimeVue components
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Menu from "primevue/menu";
import Dropdown from "primevue/dropdown";
import Toolbar from "primevue/toolbar";
import AutoComplete from "primevue/autocomplete";
import MultiSelect from "primevue/multiselect";
import Sidebar from "primevue/sidebar";
import ScrollTop from "primevue/scrolltop";
import InputMask from "primevue/inputmask";
import Checkbox from "primevue/checkbox";
import Dialog from "primevue/dialog";
import Paginator from "primevue/paginator";
import ToggleButton from "primevue/togglebutton";
import Tooltip from "primevue/tooltip";
import Toast from "primevue/toast";
import ProgressSpinner from "primevue/progressspinner";
import SelectButton from "primevue/selectbutton";
import "/public/assets/css/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

// Local imports
import App from "@/App.vue";
import store from "@/store/vueStore";

// Add FontAwesome icons to library
library.add(
  faMap,
  faGasPump,
  faFilter,
  faRoute,
  faCompass,
  faCircleInfo,
  faCar,
  faBarcode,
  faMobile,
  faUnlock,
  faLock,
  faMagnifyingGlassLocation
);

// Initialize the app and its plugins/components
const app = createApp(App);
const settings = {
  apiKey: "5bd40306-276a-4ab8-a95b-8ea18d0a14b0",
  lang: "ru_RU",
  coordorder: "latlong",
  enterprise: false,
  version: "2.1",
};
app.component("TabView", TabView);
app.component("TabPanel", TabPanel);
app.component("Menu", Menu);
app.component("Dropdown", Dropdown);
app.component("Toolbar", Toolbar);
app.component("Button", Button);
app.component("AutoComplete", AutoComplete);
app.component("MultiSelect", MultiSelect);
app.component("Sidebar", Sidebar);
app.component("ScrollTop", ScrollTop);
app.component("InputText", InputText);
app.component("Toast", Toast);
app.component("InputMask", InputMask);
app.component("Dialog", Dialog);
app.component("Checkbox", Checkbox);
app.component("ToggleButton", ToggleButton);
app.component("Paginator", Paginator);
app.component("ProgressSpinner", ProgressSpinner);
app.component("SelectButton", SelectButton);
app.component("font-awesome-icon", FontAwesomeIcon);
app.directive("tooltip", Tooltip);
app.use(store).use(PrimeVue).use(YmapPlugin, settings).use(ToastService);
app.mount("#app");
